import { useEffect } from "react";
import { clientLogout, getUser, logout } from "../services/auth.js";
import firebase from "src/firebase";

const SignOutPage = () => {
  useEffect(() => {
    if (!firebase) {
      return;
    }
    if (getUser("user")?.role === "client") {
      clientLogout(firebase);
    } else {
      logout(firebase);
    }
  }, []);

  return null;
};

export default SignOutPage;
